<template>
  <div id="landingPageView">
    <SimpleHero
      v-if="simpleHeroData"
      :simple-hero-data="simpleHeroData"
      @loaded="setPageLoadingFalse"
    />
    <div v-else class="container">
      <h1>{{ pageTitle }}</h1>
    </div>
    <div class="container">
      <div class="content">
        <ContentSectionContainer :content-sections="contentSections" />
      </div>

      <ContactFormContainer
        v-if="contactForm"
        class="contact-form-container"
        :contact-form-data="contactForm"
      />
    </div>
  </div>
</template>

<script>
import helpers from '@/api/helpers/helpers'
import api from '@/api'
import SimpleHero from '@/components/Hero/SimpleHero'
import ContentSectionContainer from '@/components/Content/ContentSectionContainer'
import { mapMutations } from 'vuex'
import ContactFormContainer from '@/components/Forms/ContactFormContainer'

export default {
  name: 'LandingPage',
  components: {
    ContactFormContainer,
    ContentSectionContainer,
    SimpleHero,
  },
  data() {
    return {
      pageTitle: '',
      simpleHeroData: null,
      contentSections: [],
      contactForm: null,
    }
  },
  mounted() {
    this.loadLandingPage()
  },
  methods: {
    ...mapMutations({
      setPageLoadingFalse: 'setPageLoadingFalse',
    }),
    /**
     *
     */
    loadLandingPage() {
      const routeSlug = this.$route.params.slug

      this.setLandingPageData(
        helpers.readStoredResponse(`view.landing-page.${routeSlug}`)
      )

      api.views.loadLandingPage(routeSlug).then((response) => {
        this.setLandingPageData(response)
      })
    },
    /**
     *
     * @param response
     */
    setLandingPageData(response) {
      if (!response || !response.entry) return

      const entry = response.entry

      this.pageTitle = entry.title || ''
      this.simpleHeroData = entry.simpleHero ? entry.simpleHero[0] : null
      this.contentSections = entry.contentSections || []
      this.contactForm = entry.contactForm ? entry.contactForm[0] : null

      setTimeout(() => {
        this.setPageLoadingFalse()
      }, 600)
    },
  },
}
</script>

<style lang="scss" scoped>
#landingPageView {
  @apply bg-black;
  margin-bottom: 200px;
  padding: 60px 0;

  .container {
    @apply flex flex-wrap;
    padding-top: 20px;

    h1 {
      @apply text-white block w-full text-center;
      font-family: OswaldLight, serif;
      font-size: 55px;
      line-height: 110px;
      border-bottom: 1px solid #4f4f4f;
    }

    .content {
      @apply w-full pb-8;
    }

    .contact-form-container {
      border-top: 1px solid #4f4f4f;
    }
  }
}
</style>
