<template>
  <div :class="[{ gray: isGrayContainer }, 'content-section']">
    <h2
      v-if="contentSection.sectionTitle"
      :id="sectionTitleId"
      v-html="contentSection.sectionTitle"
    ></h2>
    <div class="content" v-html="contentSection.sectionContent"></div>
  </div>
</template>

<script>
import helpers from '@/api/helpers/helpers'

export default {
  name: 'ContentSection',
  props: {
    contentSection: {
      type: Object,
      required: true,
    },
  },
  computed: {
    sectionTitleId() {
      return this.contentSection
        ? helpers.slugify(this.contentSection.sectionTitle)
        : ''
    },
    isGrayContainer() {
      return this.contentSection
        ? this.contentSection.typeHandle === 'grayContentBlock'
        : false
    },
  },
}
</script>

<style lang="scss">
.content-section {
  @apply text-left text-white;
  padding: 10px 10px 0;

  h2 {
    @apply text-white;
    font-family: FjallaOneRegular, sans-serif;
    font-size: 28px;
    margin-bottom: 20px;
  }

  .content {
    width: 100% !important;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 1.6;
    color: #cfcfcf;
    white-space: pre-wrap;

    h3 {
      @apply text-white;
      font-family: OswaldLight, serif;
      font-size: 26px;
      line-height: 1.3em;
    }

    h4 {
      font-family: Roboto, sans-serif;
      font-size: 21px;
      font-weight: 300;
    }

    ol {
      list-style: decimal inside;
    }

    ul {
      @apply block;
      margin-bottom: 1.25em;
      list-style-position: outside;
      margin-left: 1em;
      display: block;
      list-style-type: disc;

      li {
        margin: 0;
        padding: 0;
        direction: ltr;
      }
    }

    a {
      color: #f17800;
    }
  }

  &.gray {
    border-style: solid;
    border-width: 1px;
    border-color: #8f8f8f;
    margin-bottom: 1.25em;
    padding: 1.25em;
    background: darkgrey;

    h2 {
      text-shadow: 0 1px 0 rgba(255, 255, 255, 0.3);
      color: #222222;
    }

    .content {
      color: #222222;

      ul {
        margin-left: 0;
        padding-top: 20px;
        padding-bottom: 20px;
        background-color: #231f20;
        border-left: medium none;
        border-right: medium none;
        border-top: 2px groove rgba(113, 57, 0, 0.5);
        border-bottom: 2px groove rgba(113, 57, 0, 0.3);

        li {
          color: #cfcfcf;
          margin-left: 30px;
          margin-right: 20px;
        }
      }
    }
  }

  @media screen and (min-width: 600px) {
    h2 {
      font-size: 36px;
    }

    .content {
      h3 {
        font-size: 28px;
      }

      h4 {
        font-size: 23px;
      }
    }
  }

  @media screen and (min-width: 900px) {
    padding: 20px 20px 0;

    h2 {
      font-size: 46px;
    }

    .content {
      h3 {
        font-size: 34px;
      }
    }
  }
}
</style>
