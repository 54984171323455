<template>
  <div v-if="contactFormData.formEnabled" class="contact-form-container">
    <SimpleContentBlock :simple-content-data="contactFormData" />

    <ContactForm class="w-full" :subject="contactFormData.formSubject" />
  </div>
</template>

<script>
import ContactForm from '@/components/Forms/ContactForm'
import SimpleContentBlock from '@/components/UI/SimpleContentBlock'
export default {
  name: 'ContactFormContainer',
  components: { SimpleContentBlock, ContactForm },
  props: {
    contactFormData: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.contact-form-container {
  @apply w-full;
}
</style>
