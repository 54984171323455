<template>
  <div class="content-section-container">
    <ContentSection
      v-for="contentSection in contentSections"
      :key="contentSection.id"
      :content-section="contentSection"
    />
  </div>
</template>

<script>
import ContentSection from '@/components/Content/ContentSection'
export default {
  name: 'ContentSectionContainer',
  components: { ContentSection },
  props: {
    contentSections: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
}
</script>

<style scoped></style>
