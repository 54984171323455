<template>
  <div class="simple-hero-container">
    <div class="container">
      <h1>{{ simpleHeroData.titleText }}</h1>
      <img
        :src="simpleHeroData.heroImage[0].url"
        :alt="simpleHeroData.heroImage[0].title"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SimpleHero',
  props: {
    simpleHeroData: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.preloadImage()
  },
  methods: {
    preloadImage() {
      const image = document.createElement('img')

      image.onload = () => {
        this.$emit('loaded')
      }

      image.src = this.simpleHeroData.heroImage[0].url
    },
  },
}
</script>

<style lang="scss" scoped>
.simple-hero-container {
  .container {
    border-bottom: 1px solid #4f4f4f;

    h1 {
      @apply text-white block w-full text-center my-4;
      font-family: OswaldLight, serif;
      font-size: 32px;
      line-height: 1em;
    }

    img {
      @apply mx-auto w-full;
      padding: 20px 0;
    }
  }

  @media screen and (min-width: 600px) {
    .container {
      h1 {
        font-size: 45px;
      }
    }
  }

  @media screen and (min-width: 900px) {
    .container {
      h1 {
        font-size: 55px;
      }
    }
  }
}
</style>
